.section_features {
  padding: 10px 20px 0px 20px;
}

.section_features .feature .feature-circle {
  box-shadow: 2px 2px 4px 0px #00000040;
  background: #0081c3;
  max-width: 100px;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.section_features .feature {
  text-align: center;
  padding: 20px 0px 0px 0px;
}

.section_features .feature p {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}

.section_features .feature .feature-circle img {
  /* min-width: 100%; */
  min-height: 80px;
}

.section_features .img-container {
  width: 80%;
  align-items: center;
  margin: auto;
}

.section_features h3{
  font-size: 25px;
  padding-left: 20px;
}

@media screen and (max-width:767px) {
  .section_features {
    padding: 20px 0px 20px 0px;
  }

  .row{
    justify-content: center;
  }

  .row .heading{
    display: inline-block;
    width: auto;
  }

  .section_features h3{
    font-size: 25px;
    padding-left: 0px;
  }

  .section_features .feature .feature-circle {
    max-width: 80px;
    width: 100%;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .section_features .feature .feature-circle img{
    width: 50% !important;
  }
}